import DocumentsDownloadByUserES from "./UserDesktopES";


export interface StaffProps {
  insightDetails: any;
}

const UsageES = () => {
  return (
    <div className="ai-analysis-container">
      <div className="ai-analysis-distribution">
        <h2 className="title">Descarga de Documentos</h2>
        <div className="ai-analysis-card-main">
          <h3 className="heading">Descarga Total</h3>
          <h3 className="heading">123</h3>
        </div>
      </div>
      <div className="ai-analysis-distribution">
        <h2 className="title">Descarga de Documentos por los Usuarios</h2>
        <DocumentsDownloadByUserES />
        {/* <div className="ai-analysis-cards">
                <div className="ai-analysis-card">
                  <h3 className="heading">Usuario 1</h3>
                  <h3 className="heading">55</h3>
                </div>
                <div className="ai-analysis-card">
                  <h3 className="heading">Usuario 2</h3>
                  <h3 className="heading">10</h3>
                </div>
        </div> */}
      </div>
    </div>
  );
};

export default UsageES;
