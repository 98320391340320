import {
  DeleteIcon,
  EditIcon,
  LockIcon,
} from "../../../../../assests/images/svgComponents";
import { ColumnType } from "../../../../ReuseableComponents/Table/Table.typs";
import { v4 as uuidv4 } from "uuid";

export const UserTableColumnsES: (
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onLock: (id: number) => void
) => ColumnType[] = (onDelete, onEdit, onLock) => [
  {
    name: "Nombre de Pila",
    dataKey: "first_name",
    align: "left",
    width: "10%",
  },
  {
    name: "Apellido",
    dataKey: "last_name",
    align: "left",
    width: "10%",
  },
  {
    name: "Identificación del Documento",
    dataKey: "doc_id",
    align: "left",
    width: "10%",
  },
  {
    name: "Nombre del Documento",
    dataKey: "doc_name",
    align: "left",
    width: "10%",
  },
  {
    name: "Tipo de Documento",
    dataKey: "doc_type",
    align: "left",
    width: "10%",
  },
  {
    name: "Descargado En",
    dataKey: "downloaded_at",
    align: "left",
    width: "15%",
  },
  {
    name: "IP",
    dataKey: "ip",
    align: "left",
    width: "10%",
  },
  {
    name: "Región",
    dataKey: "region",
    align: "left",
    width: "10%",
  },
  // {
  //   name: "User",
  //   dataKey: "username",
  //   align: "left",
  //   width: "10%",
  // },

  // {
  //   name: "Actions",
  //   dataKey: "actions",
  //   align: "right",
  //   width: "10%",
  //   limit: 25,
  //   render: (cellData, rowData) => {
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: '5px', paddingRight: '10px'}}>
  //         <span
  //           className="status"
  //           onClick={() => {
  //             onEdit(rowData?.id);
  //           }}
  //         >
  //           <EditIcon />
  //         </span>
  //         <span
  //           className="status"
  //           onClick={() => {
  //             onDelete(rowData?.id);
  //           }}
  //         >
  //           <DeleteIcon />
  //         </span>
  //         <span
  //           className="status"
  //           onClick={() => {
  //             onLock(rowData?.id);
  //           }}
  //         >
  //           <LockIcon />
  //         </span>
  //       </div>
  //     );
  //   },
  // },
];

export const UserDummyData = [
  {
    first_name: "usuario",
    last_name: "1",
    doc_id: "XMTF861R",
    doc_type: "Ley",
    downloaded_at: "01-04-2024 09:30 AM",
    doc_name: "Ley-1 2020",
    ip: "192.178.1.1",
    region: "Puerto Rico"
  },
  {
    first_name: "usuario",
    last_name: "2",
    doc_id: "XMTF862R",
    doc_type: "RC",
    downloaded_at: "01-04-2024 10:00 AM",
    doc_name: "Ley-2 2020",
    ip: "192.178.1.2",
    region: "Puerto Rico"
  },
  {
    first_name: "usuario",
    last_name: "3",
    doc_id: "XMTF863R",
    doc_type: "Ley",
    downloaded_at: "01-04-2024 07:50 AM",
    doc_name: "Ley-3 2020",
    ip: "192.178.1.3",
    region: "Puerto Rico"
  },
  {
    first_name: "usuario",
    last_name: "4",
    doc_id: "XMTF864R",
    doc_type: "RC",
    downloaded_at: "01-04-2024 09:25 AM",
    doc_name: "Ley-4 2020",
    ip: "192.178.1.4",
    region: "Puerto Rico"
  },
];
