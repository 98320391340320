import React, { useState } from "react";
import "./SearchDocuments.scss";
import { Controller, useForm } from "react-hook-form";
import { Input } from "reactstrap";
import RadioButtonsPropertyES from "./RadioButtonsPropertyES";
import FooterES from "../FooterES";
import PropertyHeaderES from "../Header/PropertyHeaderES";
import { categoriesES } from "./Data/DataES";
import FormSelector from "../FormSelector/FormSelector";

export interface search {
  name: string;
  law_number: any;
  type: any;
  keywords: string;
}
type Props = {};

const SearchPropertyES = (Props: Props) => {

  const [isRadio, setIsRadio] = useState<any>(1);
  
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  
  const { handleSubmit, control } = useForm<search>({ 
    defaultValues: {
      name: "",
      type: isRadio,
      keywords: "",
    },
    mode: "onChange",
    // resolver: yupResolver(UserInfoFormValidation),
  });

  if (!isRadio) {
    return null;
  }
  let searchType;
  let informationText: any;
  if (isRadio === 1) {
    searchType = "Law";
    informationText = "Ley";
  } else if (isRadio === 2) {
    searchType = "Joint Resolution";
    informationText = "RC";
  } else if (isRadio === 3) {
    searchType = "Veto";
    informationText = "vetos";
  } else if (isRadio === 4) {
    searchType = "Reglamento de Agencias";
    informationText = "Reglamento de Agencias";
  } else if (isRadio === 5) {
    searchType = "Reglamento Municipal";
    informationText = "Reglamento Municipal";
  } else if (isRadio === 6) {
    searchType = "Municipal Ordinance";
    informationText = "MO";
  } else if (isRadio === 7) {
    searchType = "Municipal Resolution";
    informationText = "M-Res";
  } else {
    searchType = "Law";
    informationText = "LEY";
  }


  return (
    <div className="search-document-page">
      <PropertyHeaderES />
      <div className="search-documents-container">
        <div className="search-document">
          <div className="form-container">
            <h1 className="page-title">Búsqueda de Propiedad Intelectual</h1>
            <div className="radio-buttons">
              <RadioButtonsPropertyES
                isRadio={isRadio}
                setIsRadio={setIsRadio}
              />
            </div>

            <form
              className="search-documents-form"
              // onSubmit={handleSubmit}
            >
            <div className="search-bars">
              <h3 className="search-title">Buscar por Categoría:</h3>
              <FormSelector
                value={selectedCategory}
                options={categoriesES}
                onChange={handleYearChange}
              />
            </div>
              <div className="search-bars">
                <h3 className="search-title">Búsqueda por:</h3>
                <Controller
                  name="name"
                  control={control}
                  defaultValue="name"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        value={value}
                        error={error}
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Buscar por nombre"
                        onChange={onChange}
                        className="document-input"
                      />
                    );
                  }}
                />
                <Controller
                  name="keywords"
                  control={control}
                  defaultValue="keywords"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        value={value}
                        error={error}
                        id="keywords"
                        type="text"
                        name="keywords"
                        placeholder="Buscar por palabras clave"
                        onChange={onChange}
                        className="document-input"
                      />
                    );
                  }}
                />
              </div>
              <button
                className="document-button"
                type="submit"
              >
                Buscar
              </button>
              <div className="note">
                <h6>
                  Si requieren una ley certificada deberán pasar por el
                  Departamento de Estado toda vez que conlleva costo.
                </h6>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {/* <div style={{ display: token ? "block" : "none" }}>
          <UserProfile />
        </div> */}
        <FooterES />
      </div>
    </div>
  );
};

export default SearchPropertyES;
