export const years = [
    { label: "Select Year", value: "Select Year" },
    { label: "1970", value: "1970" },
    { label: "1971", value: "1971" },
    { label: "1972", value: "1972" },
    { label: "1973", value: "1973" },
    { label: "1974", value: "1974" },
    { label: "1975", value: "1975" },
    { label: "1976", value: "1976" },
    { label: "1977", value: "1977" },
    { label: "1978", value: "1978" },
    { label: "1979", value: "1979" },
    { label: "1980", value: "1980" },
    { label: "1981", value: "1981" },
    { label: "1982", value: "1982" },
    { label: "1983", value: "1983" },
    { label: "1984", value: "1984" },
    { label: "1985", value: "1985" },
    { label: "1986", value: "1986" },
    { label: "1987", value: "1987" },
    { label: "1988", value: "1988" },
    { label: "1989", value: "1989" },
    { label: "1990", value: "1990" },
    { label: "1991", value: "1991" },
    { label: "1992", value: "1992" },
    { label: "1993", value: "1993" },
    { label: "1994", value: "1994" },
    { label: "1995", value: "1995" },
    { label: "1996", value: "1996" },
    { label: "1997", value: "1997" },
    { label: "1998", value: "1998" },
    { label: "1999", value: "1999" },
    { label: "2000", value: "2000" },
    { label: "2001", value: "2001" },
    { label: "2002", value: "2002" },
    { label: "2003", value: "2003" },
    { label: "2004", value: "2004" },
    { label: "2005", value: "2005" },
    { label: "2006", value: "2006" },
    { label: "2007", value: "2007" },
    { label: "2008", value: "2008" },
    { label: "2009", value: "2009" },
    { label: "2010", value: "2010" },
    { label: "2011", value: "2011" },
    { label: "2012", value: "2012" },
    { label: "2013", value: "2013" },
    { label: "2014", value: "2014" },
    { label: "2015", value: "2015" },
    { label: "2016", value: "2016" },
    { label: "2017", value: "2017" },
    { label: "2018", value: "2018" },
    { label: "2019", value: "2019" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
  ];
  export const statusOptions = [
    { label: "Select Status", value: "Select Status" },
    { label: "Active", value: "Active" },
    { label: "Revoked", value: "Revoked" },
    { label: "Amended", value: "Amended" },
    { label: "None", value: "None" },
  ];
  export const agenciesOptions = [
    { label: "Select Agency", value: "Select Agency" },
    {
      label: "Industrial Facilities Administration (AFICA)",
      value: "Adm. of Industrial Facilities (AFICA)",
    },
    {
      label: "Mental Health and Addiction Service Admin",
      value: "Mental Health and Addiction Service Admin",
    },
    {
      label: "Adm. of Services and Agricultural Development",
      value: "Adm. of Services and Agricultural Development",
    },
    {
      label: "Adm. For the Rehabilitation of Communities",
      value: "Adm. For the Rehabilitation of Communities",
    },
    {
      label: "Energy Affairs Administration",
      value: "Energy Affairs Administration",
    },
    {
      label: "Federal Affairs Administration",
      value: "Federal Affairs Administration",
    },{
      label: "Accident Compensation Administration",
      value: "Accident Compensation Administration",
    },
    {
      label: "Correction Management",
      value: "Correction Management",
    },
    {
      label: "Work Rights Administration",
      value: "Work Rights Administration",
    },
    {
      label: "Housing Improvement and Development Administration",
      value: "Housing Development and Improvement Administration",
    },
    {
      label: "Health Facilities Administration",
      value: "Health Facilities Administration",
    },
    {
      label: "Administration for Families and Children",
      value: "Administration of Families and Children",
    },
    {
      label: "Cooperative Development Administration",
      value: "Cooperative Development Administration",
    },{
      label: "Economic Development Administration",
      value: "Economic Development Administration",
    },
    {
      label: "Youth Institutions Administration",
      value: "Youth Institutions Administration",
    },
    {
      label: "Equestrian Sport Industry Administration",
      value: "Equestrian Sport Industry Administration",
    },
    {
      label: "Retirement Systems Administration",
      value: "Withdrawal Systems Administration",
    },
    {
      label: "Natural Resources Management",
      value: "Natural Resource Management",
    },
    {
      label: "Regulations and Permits Administration",
      value: "Regulations and Permissions Administration",
    },
    {
      label: "Vocational Rehabilitation Administration",
      value: "Vocational Rehabilitation Administration",
    },
    {
      label: "Health Insurance Administration",
      value: "Health Insurance Administration",
    },{
      label: "Addiction and Mental Health Services Administration (ASSMCA)",
      value: "Addiction and Mental Health Services Administration (ASSMCA)",
    },
    {
      label: "General Services Administration",
      value: "General Services Administration",
    },
    {
      label: "Medical Services Administration",
      value: "Medical Services Administration",
    },
    { label: "Land Management", value: "Land Management" },
    {
      label: "Public Housing Administration",
      value: "Public Housing Administration",
    },
    {
      label: "Administration for Futures Training",
      value: "Administration for Futures Training",
    },
    {
      label: "Administration for Care and Development",
      value: "Administration for Care and Development",
    },
    {
      label: "Child Support Administration",
      value: "Child Support Administration",
    },{
      label: "Human Resources Administration and Trans. (OATRH)",
      value: "Human Resources Administration and Trans. (OATRH)",
    },
    {
      label: "State Emergency Management Agency",
      value: "State Emergency Management Agency",
    },
    {
      label: "Municipal Financing Agencies",
      value: "Municipal Financing Agencies",
    },
    { label: "Commercial Agriculture", value: "Commercial Agriculture" },
    {
      label: "Aqueduct and Sewer Authority",
      value: "Aqueduct and Sewer Authority",
    },
    {
      label: "Financial Advisory Authority and Fiscal Agency of Puerto Rico",
      value: "Financial Advisory Authority and Fiscal Agency of Puerto Rico",
    },
    {
      label: "Highways and Transportation Authority",
      value: "Highways and Transportation Authority",
    },
    {
      label: "Culebra Conservation and Development Authority",
      value: "Culebra Conservation and Development Authority",
    },
    {
      label: "Solid Waste Authority",
      value: "Solid Waste Authority",
    },
    {
      label: "Public Buildings Authority",
      value: "Public Buildings Authority",
    },
    {
      label: "Electric Power Authority",
      value: "Electric Power Authority",
    },{ label: "Ports Authority", value: "Ports Authority" },
    {
      label: "Puerto Rico Telephone Authority",
      value: "Puerto Rico Telephone Authority",
    },
    {
      label: "Puerto Rico Land Authority",
      value: "Puerto Rico Land Authority",
    },
    { label: "Puerto Rico Integrated Transportation Authority", value: "Puerto Rico Integrated Transportation Authority" },
    { label: "Shipping Authority", value: "Shipping Authority" },
    {
      label: "Puerto Rico Convention Center District Authority",
      value: "Puerto Rico Convention Center District Authority",
    },
    { label: "Ponce Port Authority", value: "Ponce Port Authority" },
    {
      label: "Metropolitan Bus Authority",
      value: "Metropolitan Bus Authority",
    },
    {
      label: "Infrastructure Financing Authority",
      value: "Infrastructure Financing Authority",
    },
    { label: "Housing Finance Authority", value: "Housing Finance Authority" },
    {
      label: "Roosevelt Roads Local Redevelopment Authority",
      value: "Roosevelt Roads Local Redevelopment Authority",
    },{
      label: "Authority for Public and Private Partnerships",
      value: "Authority for Public and Private Partnerships",
    },
    {
      label: "Cooperative Bank of Puerto Rico",
      value: "Cooperative Bank of Puerto Rico",
    },
    {
      label: "Economic Development Bank for Puerto Rico",
      value: "Economic Development Bank for Puerto Rico",
    },
    {
      label: "Fometo Government Bank for Puerto Rico",
      value: "Fometo Government Bank for Puerto Rico",
    },
    {
      label: "Comprehensive Cancer Center",
      value: "Comprehensive Cancer Center",
    },
    {
      label: "Diabetes Center for Puerto Rico",
      value: "Diabetes Center for Puerto Rico",
    },
    {
      label: "Municipal Revenue Collection Center",
      value: "Municipal Revenue Collection Center",
    },
    {
      label: "University College of Criminal Justice",
      value: "University College of Criminal Justice",
    },
    {
      label: "Administration System Appeal Commission",
      value: "Administration System Appeal Commission",
    },
    {
      label: "Civil Rights Commission",
      value: "Civil Rights Commission",
    },{
      label: "Puerto Rico Cooperative Development Commission",
      value: "Puerto Rico Cooperative Development Commission",
    },
    {
      label: "Commission of Investigation, Prosecution",
      value: "Investigation Commission, Prosecution",
    },
    {
      label: "Government of Puerto Rico Gaming Commission",
      value: "Government of Puerto Rico Gaming Commission",
    },
    {
      label: "Police Commission",
      value: "Police Commission",
    },
    {
      label: "Puerto Rico Pilotage Commission",
      value: "Puerto Rico Pilotage Commission",
    },
    {
      label: "Commission for the Protection and Strengthening of the Family",
      value: "Commission for the Protection and Strengthening of the Family",
    },
    {
      label: "Service Labor Relations Commission",
      value: "Service Labor Relations Commission",
    },
    { label: "Traffic Safety Commission", value: "Traffic Safety Commission" },
    { label: "Public Safety and Protection Commission", value: "Public Safety and Protection Commission" },
    {
      label: "Public Service Commission",
      value: "Public Service Commission",
    },
    {
      label: "Commission of the Port of Mayagüez",
      value: "Commission of the Port of Mayagüez",
    },{
      label: "Special Permanent Commission of Retirement Systems",
      value: "Special Permanent Commission of Retirement Systems",
    },
    {
      label: "State Election Commission",
      value: "State Election Commission",
    },
    {
      label: "Puerto Rico Industrial Commission",
      value: "Puerto Rico Industrial Commission",
    },
    {
      label: "Commission to Combat Crime",
      value: "Commission to Fight Crime",
    },
    {
      label: "Commission to Ventilate Municipal Complaints",
      value: "Commission to Ventilate Municipal Complaints",
    },
    {
      label: "Commissioner of Financial Institutions",
      value: "Commissioner of Financial Institutions",
    },
    {
      label: "Real Estate Disposal Evaluation Committee",
      value: "Real Estate Disposition Evaluation Committee",
    },
    {
      label: "Puerto Rico Trade and Export Company",
      value: "Puerto Rico Trade and Export Company",
    },{
      label: "Business Development Company",
      value: "Business Development Company",
    },
    {
      label: "Cooperative Development Company",
      value: "Cooperative Development Company",
    },
    {
      label: "Industrial Development Company",
      value: "Industrial Development Company",
    },
    {
      label: "Puerto Rico National Parks Company",
      value: "Puerto Rico National Parks Company",
    },
    {
      label: "Tourism Company",
      value: "Tourist Company",
    },
    {
      label: "Company for the Integrar Development of Cantera Peninsula",
      value: "Company for the Development Integrate Península de Cantera",
    },
    {
      label: "Human Rec. and Occupational Development Council",
      value: "Human Rec. and Occupational Development Council",
    },
    {
      label: "Council of Higher Education of Puerto Rico",
      value: "Council of Higher Education of Puerto Rico",
    },
    { label: "Council for Occupational Technical Education", value: "Council for Occupational Technical Education" },
    {
      label: "General Education Council",
      value: "General Education Council",
    },
    {
      label: "Council for Heritage Protection",
      value: "Council for Heritage Protection",
    },{
      label: "Puerto Rico Sugar Corporation",
      value: "Puerto Rico Sugar Corporation",
    },
    {
      label: "Commercial and Agricultural Credit and Development Corporation",
      value: "Commercial and Agricultural Credit and Development Corporation",
    },
    {
      label: "Corporation of Training and Work Companies",
      value: "Corporation of Training and Work Companies",
    },
    {
      label: "Symphony Orchestra Corporation",
      value: "Symphony Orchestra Corporation",
    },
    {
      label: "Musical Arts Corporation",
      value: "Musical Arts Corporation",
    },
    {
      label: "Puerto Rico Corporation for Public Broadcasting",
      value: "Puerto Rico Corporation for Public Broadcasting",
    },
    {
      label:
        "Mineral Resources Corporation",
      value:
        "Mineral Resources Corporation",
    },
    {
      label: "Agricultural Insurance Corporation",
      value: "Agricultural Insurance Corporation",
    },
    {
      label: "Corporation of the Cardiovascular Center of Puerto Rico and the Caribbean",
      value: "Corporation of the Cardiovascular Center of Puerto Rico and the Caribbean",
    },
    {
      label: "Corporation of the Center of Fine Arts",
      value: "Fine Arts Center Corporation",
    },
    {
      label: "Music Conservatory Corporation",
      value: "Music Conservatory Corporation",
    },
    {
      label: "State Insurance Fund Corporation",
      value: "State Insurance Fund Corporation",
    },{
      label: "Caño Martín Peña Project Corporation",
      value: "Caño Martín Peña Project Corporation",
    },
    {
      label: "Corporation for the Development of Cinema",
      value: "Film Development Corporation",
    },
    {
      label: "Corporation for Rural Development",
      value: "Corporation for Rural Development",
    },
    {
      label: "Corporation for Public Financing",
      value: "Corporation for Public Financing",
    },
    {
      label: "Corporation for the Supervision and Insurance of Cooperatives",
      value: "Corporation for the Supervision and Insurance of Cooperatives",
    },
    {
      label: "Public Corporation for Supervision and Insurance",
      value: "Public Corporation for Supervision and Insurance",
    },
    {
      label: "Puerto Rico Fire Department",
      value: "Puerto Rico Fire Department",
    },
    {
      label: "Medical Emergency Corps",
      value: "Emergency Medical Corps",
    },
    {
      label: "Ombudsman for Persons with Disabilities",
      value: "Ombudsman for Persons with Disabilities",
    },
    {
      label: "Department of Agriculture",
      value: "Department of Agriculture",
    },{
      label: "Puerto Rican Community Affairs Department",
      value: "Puerto Rican Community Affairs Department",
    },
    {
      label: "Department of Consumer Affairs",
      value: "Department of Consumer Affairs",
    },
    {
      label: "Department of Correction and Rehabilitation",
      value: "Department of Correction and Rehabilitation",
    },
    {
      label: "Department of Economic Development and Trade",
      value: "Department of Economic Development and Trade",
    },
    {
      label: "Education Department",
      value: "Education Department",
    },
    {
      label: "State Department",
      value: "State Department",
    },
    {
      label: "Treasury Department",
      value: "Treasury Department",
    },
    {
      label: "Department of Justice",
      value: "Department of Justice",
    },
    { label: "Family Department", value: "Family Department" },
    {
      label: "Housing Department",
      value: "Housing Department",
    },{
      label:
        "Department of Recreation and Sports",
      value:
        "Department of Recreation and Sports",
    },
    { label: "Department of Natural and Environmental Resources", value: "Department of Natural and Environmental Resources" },
    { label: "Health Department", value: "Health Department" },
    { label: "Public Security Department", value: "Public Security Department" },
    {
      label: "Department of Transportation and Public Works",
      value: "Department of Transportation and Public Works",
    },
    {
      label: "Department of Labor and Human Resources",
      value: "Department of Labor and Human Resources",
    },
    { label: "Convention Center District", value: "Convention Center District" },
    { label: "School of Fine Arts", value: "School of Fine Arts" },
    {
      label: "Puerto Rico National Guard Institutional Trust",
      value: "Puerto Rico National Guard Institutional Trust",
    },
    {
      label: "Special Communities Perpetual Trust",
      value: "Special Communities Perpetual Trust",
    },
    {
      label: "Innovation Fund for Agricultural Development",
      value: "Innovation Fund for Agricultural Development",
    },
    { label: "Puerto Rican Fund for Financing", value: "Puerto Rican Fund for Financing" },
    { label: "Puerto Rico National Guard", value: "Puerto Rico National Guard" },
    { label: "Puerto Rico Institute of Forensic Sciences", value: "Puerto Rico Institute of Forensic Sciences" },
    {
      label: "Institute of Puerto Rican Culture",
      value: "Institute of Puerto Rican Culture",
    },
    {
      label: "Puerto Rico Institute of Statistics",
      value: "Puerto Rico Institute of Statistics",
    },{
      label: "Institute of Puerto Rican Literature (UPR)",
      value: "Institute of Puerto Rican Literature (UPR)",
    },
    {
      label: "Construction and Subdivision Appeals Board",
      value: "Construction and Subdivision Appeals Board",
    },
    {
      label: "Sugar Board",
      value: "Sugar Board",
    },
    {
      label: "Central Board for Bid Reconsideration",
      value: "Central Bid Reconsideration Board",
    },
    {
      label: "Public Education System Appeal Board",
      value: "Public Education System Appeal Board",
    },
    {
      label: "Environmental Quality Board",
      value: "Environmental Quality Board",
    },
    {
      label: "Governing Board - University of Puerto Rico",
      value: "Governing Board - University of Puerto Rico",
    },
    {
      label: "9-1-1 Service Governing Board",
      value: "9-1-1 Service Governing Board",
    },
    { label: "Parole Board", value: "Parole Board" },
    {
      label: "Puerto Rico Planning Board",
      value: "Puerto Rico Planning Board",
    },
    {
      label: "Preference Board for Government Purchases",
      value: "Preference Board for Government Purchases",
    },{
      label: "Labor Relations Board",
      value: "Labor Relations Board",
    },
    {
      label: "Board of Trustees (UPR)",
      value: "Board of Trustees (UPR)",
    },
    {
      label: "International Banking Center Board",
      value: "International Banking Center Board",
    },
    { label: "Financial Board", value: "Financial Board" },
    { label: "Horse Board", value: "Horse Board" },
    {
      label: "Inter-Agency Board to Combat Appropriation",
      value: "Inter-Agency Board to Combat Takeover",
    },
    { label: "Puerto Rico Public Services Regulatory Board", value: "Puerto Rico Public Services Regulatory Board" },
    {
      label: "Interest Rate Regulatory Board",
      value: "Interest Rate Regulatory Board",
    },
    {
      label: "Installment Sales Regulatory Board",
      value: "Regulatory Board of Installment Sales",
    },
    { label: "Land Use and Permit Review Board", value: "Land Use and Permit Review Board" },
    { label: "Puerto Rico Energy Bureau", value: "Puerto Rico Energy Bureau" },
    {
      label: "Special Investigations Bureau",
      value: "Special Investigations Bureau",
    },
    {
      label: "Puerto Rico Police Bureau",
      value: "Puerto Rico Police Bureau",
    },{
      label: "Telecommunications Bureau",
      value: "Telecommunications Bureau",
    },
    {
      label: "Puerto Rico Bureau of Transportation and Other Public Services",
      value: "Puerto Rico Bureau of Transportation and Other Public Services",
    },
    {
      label: "General Coordinator Office for Socioeconomic Financing and Self-management",
      value: "General Coordinator Office for Socioeconomic Financing and Self-management",
    },
    { label: "Administration Office of the Attorney General's Office", value: "Administration Office of the Attorney General's Office" },
    {
      label: "Office of Administration and Transformation of Human Resources of the Government of Puerto Rico",
      value: "Office of Administration and Transformation of Human Resources of the Government of Puerto Rico",
    },
    {
      label: "Youth Affairs Office",
      value: "Youth Affairs Office",
    },
    {
      label: "Labor Affairs Office",
      value: "Labor Affairs Office",
    },
    {
      label: "Office of Special Communities of Puerto Rico",
      value: "Office of Special Communities of Puerto Rico",
    },
    {
      label:
        "Puerto Rico Office of Drug Control",
      value:
        "Puerto Rico Office of Drug Control",
    },
    {
      label: "Government Ethics Office",
      value: "Office of Government Ethics",
    },
    {
      label: "Permit Management Office",
      value: "Permit Management Office",
    },{
      label: "Management and Budget Office",
      value: "Office of Management and Budget",
    },
    {
      label: "Office of the Dairy Industry",
      value: "Office of the Dairy Industry",
    },
    {
      label: "Office of the Women's Ombudsman",
      value: "Office of the Women's Advocate",
    },
    {
      label: "Office of the Patient Advocate",
      value: "Office of the Patient Advocate",
    },
    {
      label: "Office of Special Communities",
      value: "Office of Special Communities",
    },
    {
      label:
        "Office of Regulation and Certification of Health Professionals",
      value:
        "Office of Regulation and Certification of Health Professionals",
    },
    { label: "Office of Pre-Trial Services", value: "Office of Pre-Trial Services" },
    { label: "Office of the Municipal Affairs Commissioner", value: "Office of the Municipal Affairs Commissioner" },
    {
      label: "Office of the Commissioner of Financial Institutions",
      value: "Office of the Commissioner of Financial Institutions",
    },
    {
      label: "Office of the Insurance Commissioner",
      value: "Office of the Insurance Commissioner",
    },
    {
      label: "Office of the Comptroller",
      value: "Office of the Controller",
    },{
      label: "Office of the Election Comptroller",
      value: "Office of the Election Comptroller",
    },
    {
      label: "Governor's Office",
      value: "Governor's Office",
    },
    {
      label: "Office of the Inspector of Cooperatives of Puerto Rico",
      value: "Office of the Cooperative Inspector of Puerto Rico",
    },
    {
      label: "Office of the Inspector General of Permits",
      value: "Office of Permit Inspector General",
    },
    {
      label: "Office of the Inspector General of Puerto Rico",
      value: "Office of the Inspector General of Puerto Rico",
    },
    {
      label: "Office of the Attorney for Persons with Disabilities",
      value: "Office of the Attorney for Persons with Disabilities",
    },
    {
      label: "Office of the Puerto Rican Veterans Advocate",
      value: "Office of the Puerto Rican Veterans Advocate",
    },
    {
      label: "Office of the Citizen's Attorney",
      value: "Office of the Citizen's Attorney",
    },
    {
      label: "State Historic Preservation Office",
      value: "State Historic Preservation Office",
    },
    {
      label: "State Office for Public Energy Policy",
      value: "State Office of Public Energy Policy",
    },{
      label: "Office for Administration of Assets of the Extinct CRUV",
      value: "Office for Administration of Assets of the Extinct CRUV",
    },
    {
      label: "Office for Aid and Service Coordination",
      value: "Office for Aid and Service Coordination",
    },
    { label: "Office for Community Development", value: "Office for Community Development" },
    {
      label: "Office for Socioeconomic and Community Development of Puerto Rico (ODSEC)",
      value: "Office for Socioeconomic and Community Development of Puerto Rico (ODSEC)",
    },
    {
      label: "Office for Socioeconomic Financing",
      value: "Office for Socioeconomic Financing",
    },
    {
      label: "Office for Public School Improvement",
      value: "Office for Public School Improvement",
    },
    {
      label: "Office for Aging Affairs",
      value: "Office for Aging Affairs",
    },
    {
      label: "Prosecutor's Office for the Elderly",
      value: "Prosecutor's Office for the Elderly",
    },
    {
      label: "Independent Special Prosecutor Panel",
      value: "Independent Special Prosecutor Panel",
    },
    {
      label: "Puerto Rico Statehood Commission",
      value: "Puerto Rico Statehood Commission",
    },
    {
      label: "Central Cancer Registry",
      value: "Central Cancer Registry",
    },{
      label: "Teacher Retirement System",
      value: "Teacher Retirement System",
    },
    {
      label:
        "Medical Examining Court",
      value:
        "Medical Examining Court",
    },
  ];

  export const municipalOptions = [
    { label: "Select Municipality", value: "Select Municipality" },{ label: "Attached", value: "Attached" },
    { label: "Water", value: "Water" },
    { label: "Aguadilla", value: "Aguadilla" },
    { label: "Good Water", value: "Good Water" },
    { label: "Aibonito", value: "Aibonito" },
    { label: "Arecibo", value: "Arecibo" },
    { label: "Stream", value: "Stream" },
    { label: "Añasco", value: "Añasco" },
    { label: "Barceloneta", value: "Barceloneta" },
    { label: "Barranquitas", value: "Barranquitas" },
    { label: "Bayamón", value: "Bayamón" },
    { label: "Red Cape", value: "Red Cape" },
    { label: "Caguas", value: "Caguas" },
    { label: "Camuy", value: "Camuy" },
    { label: "Canóvanas", value: "Canóvanas" },
    { label: "Carolina", value: "Carolina" },
    { label: "Cataño", value: "Cataño" },
    { label: "Cayey", value: "Cayey" },
    { label: "Ceiba", value: "Ceiba" },
    { label: "Cials", value: "Cials" },
    { label: "Citron", value: "Citron" },
    { label: "Coamo", value: "Coamo" },
    { label: "Eat", value: "Eat" },
    { label: "Corozal", value: "Corozal" },
    { label: "Snake", value: "Snake" },
    { label: "Gold", value: "Gold" },
    { label: "Fajardo", value: "Fajardo" },
    { label: "Florida", value: "Florida" },
    { label: "Guayama", value: "Guayama" },
    { label: "Guayanilla", value: "Guayanilla" },
    { label: "Guaynabo", value: "Guaynabo" },
    { label: "Gurabo", value: "Gurabo" },
    { label: "Guanica", value: "Guanica" },
    { label: "Hatillo", value: "Hatillo" },{ label: "Anthills", value: "Anthills" },
    { label: "Humacao", value: "Humacao" },
    { label: "Isabella", value: "Isabella" },
    { label: "Jayuya", value: "Jayuya" },
    { label: "Juana Diaz", value: "Juana Diaz" },
    { label: "Rush", value: "Rush" },
    { label: "Lajas", value: "Lajas" },
    { label: "Lares", value: "Lares" },
    { label: "Las Marías", value: "Las Marías" },
    { label: "The Stones", value: "The Stones" },
    { label: "Loiza", value: "Loiza" },
    { label: "Luquillo", value: "Luquillo" },
    { label: "Manatee", value: "Manatee" },
    { label: "Maricao", value: "Maricao" },
    { label: "Maunabo", value: "Maunabo" },
    { label: "Mayaguez", value: "Mayaguez" },
    { label: "Mocha", value: "Mocha" },
    { label: "Morovis", value: "Morovis" },
    { label: "Naguabo", value: "Naguabo" },
    { label: "Orange", value: "Orange" },
    { label: "Orocovis", value: "Orocovis" },
    { label: "Sideburns", value: "Sideburns" },
    { label: "Peñuelas", value: "Peñuelas" },
    { label: "Ponce", value: "Ponce" },
    { label: "Quebradillas", value: "Quebradillas" },
    { label: "Corner", value: "Corner" },
    { label: "Rio Grande", value: "Rio Grande" },
    { label: "Big Sheet", value: "Big Sheet" },
    { label: "Salt", value: "Salt" },
    { label: "San German", value: "San German" },{ label: "San Juan", value: "San Juan" },
    { label: "San Lorenzo", value: "San Lorenzo" },
    { label: "San Sebastian", value: "San Sebastian" },
    { label: "Saint Elizabeth", value: "Saint Elizabeth" },
    { label: "Toa Alta", value: "Toa Alta" },
    { label: "Toa Baja", value: "Toa Baja" },
    { label: "Trujillo Alto", value: "Trujillo Alto" },
    { label: "Utuado", value: "Utuado" },
    { label: "Vega Alta", value: "Vega Alta" },
    { label: "Vega Baja", value: "Vega Baja" },
    { label: "Vieques", value: "Vieques" },
    { label: "Villalba", value: "Villalba" },
    { label: "Yabucoa", value: "Yabucoa" },
    { label: "Yauco", value: "Yauco" },
  ];
  
  export const categories = [
    {"label": "Select a Category", "value": "Select a Category"},
    {"label": "Animación", "value": "Animación"},
    {"label": "Antologías", "value": "Antologías"},
    {"label": "Anuario", "value": "Anuario"},
    {"label": "Arquitectónico", "value": "Arquitectónico"},
    {"label": "Arquitectura", "value": "Arquitectura"},
    {"label": "Artesanal", "value": "Artesanal"},
    {"label": "Artesanía", "value": "Artesanía"},
    {"label": "Auto Ayuda", "value": "Auto Ayuda"},
    {"label": "Autobiografía", "value": "Autobiografía"},
    {"label": "Autobiográfico", "value": "Autobiográfico"},
    {"label": "Bingo Educativo", "value": "Bingo Educativo"},
    {"label": "Biografía", "value": "Biografía"},
    {"label": "Biográfico", "value": "Biográfico"},
    {"label": "Book trailer", "value": "Book trailer"},
    {"label": "Calendario con mens", "value": "Calendario con mens"},
    {"label": "Canciones", "value": "Canciones"},
    {"label": "Científico", "value": "Científico"},
    {"label": "Comic", "value": "Comic"},
    {"label": "Conferencia", "value": "Conferencia"},
    {"label": "Contrato", "value": "Contrato"},
    {"label": "Coreografía", "value": "Coreografía"},
    {"label": "Crítica", "value": "Crítica"},
    {"label": "Crítica Literaria", "value": "Crítica Literaria"},
    {"label": "Crítico", "value": "Crítico"},
    {"label": "Crónicas", "value": "Crónicas"},
    {"label": "Crucigrama", "value": "Crucigrama"},
    {"label": "Cuentos", "value": "Cuentos"},
    {"label": "Cuestionario", "value": "Cuestionario"},
    {"label": "Currículo", "value": "Currículo"},
    {"label": "Decimarlo", "value": "Decimarlo"},
    {"label": "Deportes", "value": "Deportes"},
    {"label": "Dibujos", "value": "Dibujos"},
    {"label": "Diseño", "value": "Diseño"},
    {"label": "Disertación", "value": "Disertación"},
    {"label": "Documental TV", "value": "Documental TV"},
    {"label": "Documento Médico", "value": "Documento Médico"},
    {"label": "DVD Coreografía", "value": "DVD Coreografía"},
    {"label": "Ensayo", "value": "Ensayo"},
    {"label": "Epístolas", "value": "Epístolas"},
    {"label": "Escritos", "value": "Escritos"},
    {"label": "Escultura", "value": "Escultura"},
    {"label": "Evento", "value": "Evento"},
    {"label": "Filosofía", "value": "Filosofía"},
    {"label": "Folleto", "value": "Folleto"},
    {"label": "Fotografía", "value": "Fotografía"},
    {"label": "Gastronómico", "value": "Gastronómico"},
    {"label": "Gráfico", "value": "Gráfico"},
    {"label": "Guía Informativa", "value": "Guía Informativa"},
    {"label": "Guión", "value": "Guión"},
    {"label": "Himnario", "value": "Himnario"},
    {"label": "Histórico", "value": "Histórico"},
    {"label": "Juego", "value": "Juego"},
    {"label": "Largometraje", "value": "Largometraje"},
    {"label": "Legal-Histórico", "value": "Legal-Histórico"},
    {"label": "Libreto", "value": "Libreto"},
    {"label": "Libros (varios)", "value": "Libros (varios)"},
    {"label": "Liderazgo", "value": "Liderazgo"},
    {"label": "Logo", "value": "Logo"},
    {"label": "Manual", "value": "Manual"},
    {"label": "Memoria Literaria", "value": "Memoria Literaria"},
    {"label": "Memorias", "value": "Memorias"},
    {"label": "Monografía", "value": "Monografía"},
    {"label": "Motivacional", "value": "Motivacional"},
    {"label": "Mural", "value": "Mural"},
    {"label": "Música", "value": "Música"},
    {"label": "Musical", "value": "Musical"},
    {"label": "Narrativo", "value": "Narrativo"},
    {"label": "Novela", "value": "Novela"},
    {"label": "Nutricional", "value": "Nutricional"},
    {"label": "Obra Gráfica", "value": "Obra Gráfica"},
    {"label": "Obra Musical", "value": "Obra Musical"},
    {"label": "Obra Objetada", "value": "Obra Objetada"},
    {"label": "Otro (Auto Ayuda)", "value": "Otro (Auto Ayuda)"},
    {"label": "Biografía", "value": "Biografía"},
    {"label": "Genealogía", "value": "Genealogía"},
    {"label": "Histórico", "value": "Histórico"},
    {"label": "Tesis", "value": "Tesis"},
    {"label": "Tratado", "value": "Tratado"},
    {"label": "Historias cortas", "value": "Historias cortas"},
    {"label": "Paquin (Comics)", "value": "Paquin (Comics)"},
    {"label": "Pasatiempo", "value": "Pasatiempo"},
    {"label": "Pedagógico", "value": "Pedagógico"},
    {"label": "Película de 8mm 9.5mm 28 mm, Cassettes más de 30 años", "value": "Película de 8mm 9.5mm 28 mm, Cassettes más de 30 años"},
    {"label": "Pensamientos", "value": "Pensamientos"},
    {"label": "Personaje", "value": "Personaje"},
    {"label": "Pinturas", "value": "Pinturas"},
    {"label": "Poemario", "value": "Poemario"},
    {"label": "Poesía", "value": "Poesía"},
    {"label": "Prog. Computadora", "value": "Prog. Computadora"},
    {"label": "Prog. Televisión", "value": "Prog. Televisión"},
    {"label": "Programa Televisión", "value": "Programa Televisión"},
    {"label": "Propuesta", "value": "Propuesta"},
    {"label": "Prosas", "value": "Prosas"},
    {"label": "Proyecto", "value": "Proyecto"},
    {"label": "Psicología", "value": "Psicología"},
    {"label": "Psicológico", "value": "Psicológico"},
    {"label": "Psicoterapéutico", "value": "Psicoterapéutico"},
    {"label": "Reflexiones", "value": "Reflexiones"},
    {"label": "Relatos", "value": "Relatos"},
    {"label": "Religioso", "value": "Religioso"},
    {"label": "Revista", "value": "Revista"},
    {"label": "Rótulo", "value": "Rótulo"},
    {"label": "Serie T.V.", "value": "Serie T.V."},
    {"label": "Serie-Comics", "value": "Serie-Comics"},
    {"label": "Serigrafía", "value": "Serigrafía"},
    {"label": "Social", "value": "Social"},
    {"label": "Taller", "value": "Taller"},
    {"label": "Teatro", "value": "Teatro"},
    {"label": "Telenovela", "value": "Telenovela"},
    {"label": "Teología", "value": "Teología"},
    {"label": "Tesis", "value": "Tesis"},
    {"label": "Testimonio", "value": "Testimonio"},
    {"label": "TV", "value": "TV"},
    {"label": "Versos", "value": "Versos"},
    {"label": "Video", "value": "Video"},
    {"label": "Vivencias", "value": "Vivencias"}
  ];