import DocumentsDownloadByUser from "./UserDesktop";


export interface StaffProps {
  insightDetails: any;
}

const Usage = () => {
  return (
    <div className="ai-analysis-container">
      <div className="ai-analysis-distribution">
        <h2 className="title">Documents Download</h2>
        <div className="ai-analysis-card-main">
          <h3 className="heading">Total Download</h3>
          <h3 className="heading">123</h3>
        </div>
      </div>
      <div className="ai-analysis-distribution">
        <h2 className="title">Documents Download by Users</h2>
        <DocumentsDownloadByUser />
        {/* <div className="ai-analysis-cards">
                <div className="ai-analysis-card">
                  <h3 className="heading">User 1</h3>
                  <h3 className="heading">55</h3>
                </div>
                <div className="ai-analysis-card">
                  <h3 className="heading">User 2</h3>
                  <h3 className="heading">10</h3>
                </div>
        </div> */}
      </div>
      {/* <div className="ai-analysis-distribution">
        <h2 className="title">Documents Distribution by Type</h2>
        <div className="ai-analysis-cards">
          {insightDetails?.document_type_distribution?.map(
            (items: any, index: number) => {
              return (
                <div className="ai-analysis-card" key={index}>
                  <h3 className="heading">{items?.type}</h3>
                  <h3 className="heading">{items?.count}</h3>
                </div>
              );
            }
          )}
        </div>
      </div> */}
    </div>
  );
};

export default Usage;
