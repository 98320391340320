import React from "react";
import SearchPropertyES from "../components/desktop/DE/SearchDocument/SearchPropertyES";

type Props = {};

function SearchPropertyPageES(Props: Props) {

  return (
    <SearchPropertyES />
  );
}

export default SearchPropertyPageES;
